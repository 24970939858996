import React from 'react';
import '../../styles/generals/header.css';
import { NavLink, Link } from 'react-router-dom';
import logo from "../../../utils/head_foot_imgs/logo_bazzar_india.png";
import '../../../App.css';

function Header({ showNav }) {
    return (
        <>
            <div className="header-top">
                <div className="container d-flex justify-content-between">

                    <div>
                        <Link className='header-link' to="/facebook">
                            <i className="fa-brands py-3 fa-facebook"></i>
                        </Link>

                        <Link className='header-link' to="/twitter">
                            <i className="fa-brands py-3 fa-twitter"></i>
                        </Link>

                        <Link className='header-link' to="/instagram">
                            <i className="fa-brands py-3 fa-instagram"></i>
                        </Link>

                        <Link className='header-link' to="/linkedin">
                            <i className="fa-brands py-3 fa-linkedin"></i>
                        </Link>
                    </div>

                        <p className='pt-3'>Smart Choices, Smart Living </p>

                </div>
            </div>

            {showNav && <nav class="navbar navbar-expand-lg navbar-light py-1 sticky-top">
                <div class="container">
                    <Link class="navbar-brand" to="/"><img src={logo} alt='Site Logo' className='img-fluid' style={{ height: '4.3em' }} /></Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item my-2">
                                <NavLink className="nav-text" aria-current="page" to="/">Home</NavLink>
                            </li>
                            <li class="nav-item my-2">
                                <NavLink className="nav-text" to="/about">About</NavLink>
                            </li>
                            <li class="nav-item my-2">
                                <NavLink className="nav-text" to="/products">Products</NavLink>
                            </li>
                            <li class="nav-item my-2">
                                <NavLink className="nav-text" to="/contact">Contact</NavLink>
                            </li>
                            <Link to="/login"><button className='click-btn'>Login Now</button></Link>
                        </ul>
                    </div>
                </div>
            </nav>
            }


            {/* // <div className="container" style={{ padding: '15px 0px' }}>
                    //     <div className="row">
                    //         <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col logo_section">
                    //             <div className="full">
                    //                 <div className="center-desk">
                    //                     <div className="logo"> <Link to="/"><img src={logo} width="150px" alt="logo" /></Link> </div>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //         <div className="col-xl-9 col-lg-7 col-md-9 col-sm-9">
                    //             <div className="menu-area">
                    //                 <nav className="main-menu">
                    //                     <ul className="menu-area-main">
                    //                         <li>
                    //                             <NavLink to="/">Home</NavLink>
                    //                         </li>
                    //                         <li>
                    //                             <NavLink to="/about">About</NavLink>
                    //                         </li>
                    //                         <li>
                    //                             <NavLink to="/products">Products</NavLink>
                    //                         </li>
                    //                         <li>
                    //                             <NavLink to="/contact">Contact</NavLink>
                    //                         </li>
                    //                         <NavLink to="/login" className='buy'>Login</NavLink>
                    //                     </ul>
                    //                 </nav>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div> */}

        </>
    )
}

export default Header;