import axios from 'axios';
import React, { useState } from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// import {useNavigate} from 'react-router-dom';

const editPopup = {
    backgroundColor: 'white',
}

const EditModal = ({ productData }) => {

    const [formData, setFormData] = useState({ name: productData.name, description: productData.description, purchase_price: productData.purchase_price, sale_price: productData.sale_price });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    // const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        try {
            console.log(productData.id);

            axios.put(`http://localhost:8081/api/products/${productData.id}`, formData);
            localStorage.removeItem('products');
            window.location.reload();
            // alert('Product Updated Successfully');
            // navigate('/admin/products');
        }
        catch (error) {
            console.error(error);
            alert('Product Details not updated');
        }
    };

    return (
        <>
            <Popup trigger=
                {<button className='click-btn'>Edit Product&nbsp;
                    <i className="fa-regular fa-pen-to-square"></i></button>}
                modal nested style={editPopup} >

                {/* Edit Product Section */}
                {/* <div className='py-4 px-4'>
                    <h4 className='text-orange'>Edit Product Details</h4>
                    <hr />

                    <form className='my-5' onSubmit={handleSubmit}>

                        <h5 className='mt-4'>Product Name</h5>
                        <input type='text' name='name' className='input-area' onChange={handleChange} value={formData.name}></input>

                        <h5 className='mt-4'>Description</h5>
                        <input type='text' name='description' className='input-area' onChange={handleChange} value={formData.description}></input>

                        <h5 className='mt-4'>Original Price</h5>
                        <input type='text' name='original' className='input-area' onChange={handleChange} value={formData.sale_price}></input>

                        <h5 className='mt-4'>Discounted Price</h5>
                        <input type='text' name='discount' className='input-area' onChange={handleChange} value={formData.purchase_price}></input>

                        <button className='mt-4 click-btn' type='submit'>Update Details</button>

                    </form>
                </div> */}
                {/* Edit Product Section Ends */}


                {
                    close => (
                        <div className='py-4 px-4'>
                            <h4 className='text-orange'>Edit Product Details</h4>
                            <hr />

                            <form className='my-5' onSubmit={handleSubmit}>

                                <h5 className='mt-4'>Product Name</h5>
                                <input type='text' name='name' className='input-area' onChange={handleChange} value={formData.name}></input>

                                <h5 className='mt-4'>Description</h5>
                                <input type='text' name='description' className='input-area' onChange={handleChange} value={formData.description}></input>

                                <h5 className='mt-4'>Original Price</h5>
                                <input type='text' name='sale_price' className='input-area' onChange={handleChange} value={formData.sale_price}></input>

                                <h5 className='mt-4'>Discounted Price</h5>
                                <input type='text' name='purchase_price' className='input-area' onChange={handleChange} value={formData.purchase_price}></input>

                                <button className='mt-4 click-btn' type='submit'>Update Details</button>
                                {/* <button className='mt-4 click-btn ms-2' onClick=
                                    {() => close()}>Get Back</button> */}

                            </form>
                        </div>

                    )
                }
            </Popup>
        </>
    )
}

export default EditModal;
