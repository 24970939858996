import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

function LineChart() {
  const chartRef = useRef(null);

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Revenue',
        data: [3000, 5000, 4000, 6000, 7000, 8000],
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradient.addColorStop(0, '#2196F3'); // Blue
          gradient.addColorStop(1, '#21CBF3'); // Light Blue

          return gradient;
        },
        borderColor: '#2196F3',
        borderWidth: 2,
        fill: true,
        tension: 0.4,
        pointRadius: 5,
        pointBackgroundColor: '#FFF',
        pointBorderColor: '#2196F3',
        pointHoverRadius: 8,
        pointHoverBackgroundColor: '#FFC107',
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            family: 'Poppins',
            weight: '600',
          },
          color: '#333',
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            family: 'Poppins',
            weight: '600',
          },
          color: '#333',
        },
      },
    },
    animation: {
      duration: 2500,
      easing: 'easeInOutCubic',
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: 16,
            family: 'Poppins',
            weight: 'bold',
          },
          color: '#333',
        },
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Line ref={chartRef} data={data} options={options} />
    </div>
  );
}

export default LineChart;
