import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/admin_styles/sidebar.css';
import { useNavigate } from 'react-router-dom';

function AdminSidebar({ isAdmin }) {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const navigate = useNavigate();

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };


    const handleLogout = () => {
        localStorage.removeItem('client_is');
        navigate('/');
        window.location.reload();
    };


    return (
        <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
            {isAdmin ? <ul className="sidebar-menu">
                <div style={{ height: '150px' }}></div>
                <NavLink to="/admin/dashboard">
                    <i className='fa fa-home'></i>
                    <li>Dashboard</li>
                </NavLink>
                <NavLink to="/admin/products">
                    <i className='fa fa-th-list'></i>
                    <li>Products</li>
                </NavLink>
                <NavLink to="/admin/sales">
                    <i className='fa fa-history'></i>
                    <li>Sale History</li>
                </NavLink>
                <NavLink to="/admin/clients">
                    <i className='fa fa-user'></i>
                    <li>View Clients</li>
                </NavLink>
                <NavLink to="/admin/logout" onClick={handleLogout}>
                    <i className='fa fa-sign-out'></i>
                    <li>Logout</li>
                </NavLink>
            </ul>
                :
                <ul className="sidebar-menu">
                    <div style={{ height: '150px' }}></div>
                    <NavLink to="/client/dashboard">
                        <i className='fa fa-home'></i>
                        <li>Dashboard</li>
                    </NavLink>
                    <NavLink to="/client/products">
                        <i className='fa fa-th-list'></i>
                        <li>Products</li>
                    </NavLink>
                    <NavLink to="/client/sales">
                        <i className='fa fa-history'></i>
                        <li>Order History</li>
                    </NavLink>
                    {/* <NavLink to="/admin/clients">
                        <i className='fa fa-user'></i>
                        <li>View Clients</li>
                    </NavLink> */}
                    <NavLink to="/client/logout" onClick={handleLogout}>
                        <i className='fa fa-sign-out'></i>
                        <li>Logout</li>
                    </NavLink>
                </ul>}
            <div className="sidebar-header">
                <button className="toggle-btn" onClick={toggleSidebar}>
                    {isCollapsed ? '>' : '<'}
                </button>
            </div>
        </div>
    );
}

export default AdminSidebar;
