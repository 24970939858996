import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MyCard from '../models/card';

function AdminProduct() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        // Check if products are already in localStorage
        const storedProducts = localStorage.getItem('products');
        if (storedProducts) {
            // Use cached data if available
            setProducts(JSON.parse(storedProducts));
        } else {
            // Fetch products from the API
            axios.get('http://localhost:8081/api/products')
                .then((response) => {
                    setProducts(response.data);
                    // Store products in localStorage
                    localStorage.setItem('products', JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.error('Error fetching products:', error);
                });
        }
    }, []); // Empty dependency array to run this effect once

    return (
        <div>
            <div className='product-container'>
                {products.map((product) => (
                    <MyCard key={product.id} productData={product} />
                ))}
            </div>
        </div>
    );
}

export default AdminProduct;