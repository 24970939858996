import React from 'react';
import LineChart from '../charts/line_chart';
import BarChart from '../charts/bar_chart';
import PieChart from '../charts/pi_chart';

function AdminDashboard({ clientDetails }) {
    return (
        <div className='dash-container'>
            <div className="card-cover">
                <LineChart />
            </div>
            <div className="card-cover">
                <BarChart />
            </div>
            <div className="card-cover">
                <div></div>
            </div>
            <div className="card-cover">
                <PieChart />
            </div>
        </div>
    );
}

export default AdminDashboard;