import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

function PieChart() {
  const data = {
    labels: ['Laptops', 'Mobiles', 'Accessories'],
    datasets: [
      {
        label: 'Product Categories',
        data: [350, 250, 200],
        backgroundColor: [
          'rgba(75, 192, 192, 0.9)', // Soft teal
          'rgba(54, 162, 235, 0.9)', // Light blue
          'rgba(255, 159, 64, 0.9)', // Soft orange
        ],
        hoverBackgroundColor: [
          'rgba(75, 192, 192, 1)', // Dark teal
          'rgba(54, 162, 235, 1)', // Dark blue
          'rgba(255, 159, 64, 1)', // Dark orange
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
        hoverOffset: 25, // Larger displacement on hover
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 10,
        bottom: 10,
        left: 20,
      },
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          padding: 25, // Distance between legend items and chart
          font: {
            size: 16,
            family: 'Poppins',
            weight: 'bold',
          },
          color: '#333',
        },
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 3000, // Slower and smoother animation
      easing: 'easeInOutQuad',
    },
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Pie data={data} options={options} />
    </div>
  );
}

export default PieChart;
