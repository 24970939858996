import React, { useEffect, useState } from 'react';
import '../../styles/admin_styles/view_clients.css';
import axios from 'axios';


function ViewClients({clientDetails}) {

  const [clients, setClients] = useState([]);

  useEffect(() => {
    // Check if client details are present in DB
    localStorage.removeItem('clients');
    const storedData = localStorage.getItem('clients');
    if (storedData) {
      setClients(JSON.parse(storedData));
    }
    else {
      console.log(clientDetails);
      // Fetch data using the API
      axios.get('http://localhost:8081/api/clients', {
        referId: clientDetails.referId,
      }).then((response) => {
        setClients(response.data);
        localStorage.setItem('clients', JSON.stringify(response.data));
      })
        .catch((error) => {
          console.error('Error to get Client data', error);
        });
    }
  }, [clientDetails]);

  return (
    <div className="client-card table-responsive">
      <table className='w-100'>
        <tr className="client-header">
          <th>Name</th>
          <th>Contact</th>
          <th>Client Id</th>
          <th>Refer Id</th>
          <th>Sub Clients</th>
        </tr>

        {clients.map((client) => (
          <tr className='client-data' key={client.id} client={client}>
            <td>{client.name}</td>
            <td>{client.contact}</td>
            <td>{client.client_id}</td>
            <td>{client.refer_id}</td>
            <td><button className='click-btn'>My Clients</button></td>
          </tr>
        ))}

        {/* <tr className='client-data'>
        <td>Client 1</td>
        <td>123 456 XXXX</td>
        <td>ID_101</td>
        <td>REF_101</td>
      </tr>

      <tr className='client-data'>
        <td>Client 1</td>
        <td>123 456 XXXX</td>
        <td>ID_101</td>
        <td>REF_101</td>
      </tr>

      <tr className='client-data'>
        <td>Client 1</td>
        <td>123 456 XXXX</td>
        <td>ID_101</td>
        <td>REF_101</td>
      </tr> */}


        {/* <div className="client-body"></div> */}
      </table>
    </div>
  );
}

export default ViewClients;