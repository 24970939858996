// import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState } from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Header from './components/scripts/generals/header';
import Home from './components/scripts/body_comps/home';
import Footer from './components/scripts/generals/footer';
import About from "./components/scripts/body_comps/about";
import Products from "./components/scripts/body_comps/products";
import Contact from "./components/scripts/body_comps/contact";
import Login from "./components/scripts/body_comps/login";
import MyToast from "./components/scripts/models/my_toast";
import MyNavigator from "./components/scripts/models/navigator";
import ProductDetails from "./components/scripts/models/product_details";
import ClientDashboard from "./components/scripts/client_scripts/dashboard";
import PageNotFound from "./components/scripts/generals/page_not_found";
import AdminDashboard from "./components/scripts/admin_scripts/dashboard";
import AdminSidebar from "./components/scripts/admin_scripts/sidebar";
import AdminProduct from "./components/scripts/admin_scripts/product";
import ViewClients from "./components/scripts/admin_scripts/view_clients";
import ClientProduct from "./components/scripts/client_scripts/product";
import ClientSales from "./components/scripts/client_scripts/sales";

function App() {
  const [showToast, setShowToast] = useState(false);
  const [toastColor, setToastColor] = useState('#4CAF50');
  const [toastMsg, setToastMsg] = useState("Message");

  const clientIs = localStorage.getItem('client_is');
  // localStorage.removeItem('client_is');
  const isLoggedIn = clientIs === null;
  const clientDetails = JSON.parse(clientIs);

  function handleToastData(data) {
    setShowToast(true);
    setToastColor(data.toastColor);
    setToastMsg(data.toastMsg);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  }

  function closeToast() {
    setShowToast(false);
  }

  return (
    <Router>
      <MyToast message={toastMsg} color={toastColor} show={showToast} onClose={closeToast} />

      {isLoggedIn && <Header showNav={true} />}

      {isLoggedIn ?
        <Routes>

          <Route exact path="/" element={<Home />}></Route>

          <Route exact path="/about" element={<About />}></Route>

          <Route exact path="/products" element={<Products isAdmin={false} />}></Route>

          <Route exact path="/contact" element={<Contact />}></Route>

          <Route exact path="/login" element={<Login handleToast={handleToastData} />}></Route>

          <Route exact path="/products/:prodId" element={<ProductDetails from={0} />}></Route>

          <Route exact path="*" element={<PageNotFound />}></Route>

        </Routes>
        : clientDetails.isAdmin ?
          <div className="sideapp">

            <AdminSidebar isAdmin={clientDetails.isAdmin} />

            <div className="content">

              <div style={{ backgroundColor: '#e6fbfcbd' }}>
                <Header showNav={false} />

                <Routes>

                  <Route exact path="/" element={<MyNavigator details={clientDetails.isAdmin} />}></Route>

                  <Route exact path="/admin/dashboard" element={<AdminDashboard clientDetails={clientDetails.client[0]} />}></Route>

                  <Route exact path="/admin/products" element={<AdminProduct />}></Route>

                  <Route exact path="/admin/clients" element={<ViewClients clientDetails={clientDetails} />}></Route>

                  <Route exact path="/products/:prodId" element={<ProductDetails from={1} />}></Route>

                  <Route exact path="*" element={<PageNotFound />}></Route>

                </Routes>

              </div>
            </div>
          </div> :
          <div className="sideapp">

            <AdminSidebar />

            <div className="content">
              <Routes>

                <Route exact path="/" element={<MyNavigator details={clientDetails.isAdmin} />}></Route>

                <Route exact path="/client/dashboard" element={<ClientDashboard clientDetails={clientDetails.client[0]} />}></Route>

                <Route exact path="/client/products" element={<ClientProduct clientDetails={clientDetails.client[0]} />}></Route>

                <Route exact path="/products/:prodId" element={<ProductDetails from={2} />}></Route>

                <Route exact path="/client/sales" element={<ClientSales clientDetails={clientDetails.client[0]} />}></Route>

                <Route exact path="*" element={<PageNotFound />}></Route>

              </Routes>
            </div>
          </div>}

      {isLoggedIn && <Footer />}

    </Router>
  );
}

export default App;
