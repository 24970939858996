import React from 'react';
import '../../styles/body_comps/about.css';
import WhyUs from '../generals/why_us';
import pcLayout from '../../../utils/homepage/pc_layout.png';
import Headings from '../generals/headings';

function About() {
    return (
        <>

            <Headings message="ABOUT US" />

            <section className='bg-light py-5'>
                <div className='container'>

                    <div className='row mt-4'>

                        <div className='col-lg-6 col-12 my-3'>
                            <h3>Who is Bazzar India?</h3>
                            <p className='mt-4'>We at Bazzar India have survived not only in India, but also the leap into electronic typesetting, remaining
                                essentially unchanged. We were popularised in the 2019 with the release of electronic products.</p>
                                <p>We serve the range of multiple electronic products in various categories which you want to buy.</p>

                                <p>lorem ipsum dolor sit amit. lorem ipsum dolor sit amit. lorem ipsum dolor sit amit. lorem ipsum dolor sit amit.
                                lorem ipsum dolor sit amit. lorem ipsum dolor sit amit. lorem ipsum dolor sit amit. lorem ipsum dolor sit amit.
                                lorem ipsum dolor sit amit. lorem ipsum dolor sit amit. lorem ipsum dolor sit amit. lorem ipsum dolor sit amit.
                                lorem ipsum dolor sit amit. lorem ipsum dolor sit amit. lorem ipsum dolor sit amit. lorem ipsum dolor sit amit.
                                </p>

                        </div>

                        <div className='col-lg-6 col-12 my-3'>
                            <img src={pcLayout} className='img-fluid' alt='About' />
                        </div>


                    </div>
                    {/* row ends */}

                </div>
            </section>

            {/* <div className="about">
                <div className="container">
                    <div className="row">
                        <dir className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="about_box">
                                <figure><img src={pcLayout} alt='pc_layout' /></figure>
                            </div>
                        </dir>
                        <dir className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="about_box">
                                <h3>Who is Bazzar India</h3>
                                <p>It has survived only india, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 2019 with the release of electronic products.</p>
                                <p>Bazzar india have multiple electronic products which you want to buy.</p>
                            </div>
                        </dir>
                    </div>
                </div>
            </div> */}

            <WhyUs />
        </>
    );
}

export default About;