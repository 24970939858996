import React from 'react';
import Header from '../generals/header';
import OrderDetail from '../models/orderDetail';

const ClientSales = ({clientDetails}) => {
  return (
    <>

<Header showNav={false} />

<section className='py-5 bg-light vh-100'>
  <div className='container'>

<p>Greetings {clientDetails.name}! You can view complete order history and check order details here.</p>


<div className='table-responsive mt-4'>
            <table className='w-100'>
              <tr className="client-header">
              <th>Date</th>
              <th>Invoice No</th>
                <th>Customer Name</th>
                <th>Payment Mode</th>
                <th>Total Price</th>
                <th>Details</th>
              </tr>

              <tr className='client-data'>
                <td>19/10/2024</td>
                <td>Invoice_Id1</td>
                <td>Ambani</td>
                <td>Cash</td>
                <td>Rs 200000</td>
                <td><OrderDetail /></td>
                {/*  productData={productData} Pass this prop in order details */}
              </tr>
            </table>
          </div>


  </div>
</section>

      
    </>
  )
}

export default ClientSales;
