import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function MyNavigator({ details }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (details) {
            navigate('/admin/dashboard');
        } else {
            navigate('/client/dashboard');
        }
    });

    return (
        <div></div>
    );
}

export default MyNavigator;