import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


const OrderDetail = () => {
  return (
    <>
                  <Popup trigger=
                {<button className='click-btn'>Product Details<i className="fa-solid fa-file-invoice ms-2"></i></button>}
                modal nested className='bg-white' >

                {
                    close => (
                        <div className='py-4 px-4'>
                            <div className='d-flex justify-content-between'>
                                <h4 className='text-orange'>Products Details</h4>
                                <button className='click-btn' onClick={() => close()}>Close Cart</button>
                            </div>
                            {/* flex ends */}

                            <hr />

                            <div className='cart-product mt-4'>

                                <div className='px-3'>
                                    <p>Date: </p>
                                    <p>Invoice no: </p>
                                    <p>Customer Name:</p>
                                    <p>Payment Mode:</p>
                                    <p>Products: </p>
                                    <p>Order Total = </p>
                                    <button className='click-btn'>Download Invoice<i className="fa-solid fa-file-arrow-down ms-2"></i></button>

                                </div>
                            </div>

                        </div>
                    )
                }

            </Popup>

    </>
  )
}

export default OrderDetail;
