import React from 'react';
import { Link } from 'react-router-dom';
import why1 from "../../../utils/homepage/why_1.png";
import why2 from "../../../utils/homepage/why_2.png";
import why3 from "../../../utils/homepage/why_3.png";
import why4 from "../../../utils/homepage/why_4.png";

function WhyUs() {
    return (
        <>

            <section className='my-5'>
                <div className='container text-center'>

<div className='my-4'>
                        <h2 className='heading2'>Why <strong className="text-orange">Choose Us?</strong></h2>
                        <span>High quality products available with the most reasonable prices!</span>
                        </div>

                    <div className='row my-4'>

                        <div className='col-lg-3 col-md-6 col-12 my-3'>
                            <div className='choose-area'>
                                <img src={why1} alt='why choose us' />
                                <div className='my-4'>
                                    <h5>Home Appliances</h5>
                                    <p>We provide Electronic gadgets use for home. Example: Celling Fan, Geyser, Room Heater, Iron etc.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-12 my-3'>
                            <div className='choose-area'>
                                <img src={why2} alt='why choose us' />
                                <div className='my-4'>
                                    <h5>Kitchen Appliances</h5>
                                    <p>We provide Electronic gadgets use for Kitchen. Example: Mixer Grinder, Indution, Kttle, Fan etc.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-12 my-3'>
                            <div className='choose-area'>
                                <img src={why3} alt='why choose us' />
                                <div className='my-4'>
                                    <h5>Accessories</h5>
                                    <p>We provide Electronic gadgets use for home. Example: Air Pump, Speaker, Headphones etc.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-12 my-3'>
                            <div className='choose-area'>
                                <img src={why4} alt='why choose us' />
                                <div className='my-4'>
                                    <h5>Entertainment</h5>
                                    <p>We provide Electronic gadgets which are suitable to use for home. Example: Led TV etc.</p>
                                </div>
                            </div>
                        </div>


                    </div>
                    {/* row ends */}

                    <button className='btn'><Link className="read-more">Get More Details</Link></button>


                </div>
            </section>
        </>
        // <section className='why-choose-us'>
        //     <div className="row">
        //         <div className="col-md-7 offset-md-3">
        //             <div className="title">
        //                 <h2>Why <strong className="black">choose us</strong></h2>
        //                 <span>Latest product available with best price!</span>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="choose_bg">
        //         <div className="container">
        //             <div className="white_bg">
        //                 <div className="row">
        //                     <dir className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
        //                         <div className="for_box">
        //                             <i><img src={why1} alt='box' /></i>
        //                             <h3>Home Appliance</h3>
        //                             <p>We provide Electronic gadgets use for home.<br />
        //                                 Example : Celling Fan, Geyser, Room Heater, Iron etc.</p>
        //                         </div>
        //                     </dir>
        //                     <dir className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
        //                         <div className="for_box">
        //                             <i><img src={why2} alt='box' /></i>
        //                             <h3>Kitchen Appliance</h3>
        //                             <p>We provide Electronic gadgets use for Kitchen.<br />
        //                                 Example : Mixer Grinder, Indution, Kttle, Fan etc.</p>
        //                         </div>
        //                     </dir>
        //                     <dir className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
        //                         <div className="for_box">
        //                             <i><img src={why3} alt='box' /></i>
        //                             <h3>Accessories</h3>
        //                             <p>We provide Electronic gadgets use for home.<br />
        //                                 Example:Air Pump, Speaker, Headphones etc. </p>
        //                         </div>
        //                     </dir>
        //                     <dir className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
        //                         <div className="for_box">
        //                             <i><img src={why4} alt='box' /></i>
        //                             <h3>Entertainment</h3>
        //                             <p>We provide Electronic gadgets use for home.<br />
        //                                 Example : Led TV etc. </p>
        //                         </div>
        //                     </dir>
        //                     <div className="col-md-12">
        //                         <Link className="read-more">Read More</Link>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    );
}

export default WhyUs;