import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Headings from '../generals/headings';
import '../../styles/body_comps/products.css';
import MyCard from '../models/card';


function Products({ isAdmin }) {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        // Check if products are already in localStorage
        const storedProducts = localStorage.getItem('products');
        if (storedProducts) {
            // Use cached data if available
            setProducts(JSON.parse(storedProducts));
        } else {
            // Fetch products from the API
            axios.get('http://localhost:8081/api/products')
                .then((response) => {
                    setProducts(response.data);
                    // Store products in localStorage
                    localStorage.setItem('products', JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.error('Error fetching products:', error);
                });
        }
    }, []); // Empty dependency array to run this effect once

    return (
        <>
            <Headings message="OUR PRODUCTS" />

            <div className="product py-5">
                <div className="container text-center">
                    <h5>"We package the products with best services to make you a happy customer."</h5>
                </div>
            </div>

            <section>
                <div className='product-container'>
                    {products.map((product) => (
                        <MyCard key={product.id} productData={product} />
                    ))}
                </div>
            </section>
        </>
    );
}

export default Products;