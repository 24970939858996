import React from 'react';
import '../../styles/generals/page_not_found.css';

function PageNotFound() {
    return (
        <section className="page_404">
            <div className="container">
                <div className="row">
                    <div className="my-flexer">
                        <div className="col-sm-10 col-sm-offset-1  text-center">
                            <div className="four_zero_four_bg">
                            </div>
                            <div className="contant_box_404">
                                <h1 className="text-center fw-bolder">404</h1>
                                <h3 className="h2">Look like you're lost</h3>
                                <p>the page you are looking for not avaible!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PageNotFound;