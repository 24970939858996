import React, { useState } from 'react';
import Headings from '../generals/headings';
import '../../styles/body_comps/login.css';
import axios from 'axios';

function Contact(props) {
    const [mobileNumber, setMobileNumber] = useState('');
    const [name, setName] = useState('');
    const [formMsg, setFormMsg] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Send a POST request to the backend
        axios.post('http://localhost:8081/api/addClient', {
            name: name,
            contact: mobileNumber,
            formMsg: formMsg,
        })
            .then((response) => {
                console.log('Data inserted:', response.data);
                alert('Data successfully inserted');
                handleSignIn();
            })
            .catch((error) => {
                console.error('There was an error inserting the data!', error);
            });
    };

    // Handle change in mobile number input
    const handleChange = (e) => {
        const value = e.target.value;

        // Ensure the input is numeric and no more than 10 digits
        if (value.length <= 10 && /^[0-9]*$/.test(value)) {
            setMobileNumber(value);
        }
    };

    // Handler for sign in button
    const handleSignIn = () => {
        setMobileNumber('');
        setName('');
        setFormMsg('');
    };

    return (
        <>

            <Headings message="CONTACT US" />

            <section className='bg-light'>

                    <div className='row'>

                        <div className='col-lg-6 col-12 px-5 my-5'>
                            <h2 className='fw-bold'>Get in Touch with Us</h2>
                            <form onSubmit={handleSubmit} method='POST' className='my-5'>
                                <div className='col-12 my-3'>
                                    <input className='contact-form-input' required type="text" maxLength={60} value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" />
                                </div>

                                <div className='col-12 my-3'>
                                    <input className='contact-form-input' required type="text" maxLength={10} value={mobileNumber} onChange={handleChange} minLength={10} pattern='[0-9]{10}' placeholder="Enter Contact" />
                                </div>

                                <div className='col-12 my-3'>
                                    <textarea className='contact-msg contact-form-input' style={{ height: '8em' }} required type="text" maxLength={250} value={formMsg} onChange={(e) => setFormMsg(e.target.value)} placeholder="Enter Message" />
                                </div>

                                <button className='click-btn px-4 py-3' type='submit'>Submit Now</button>
                            </form>
                        </div>


                        <div className='col-lg-6 col-12 contact-right-bg order-first order-lg-last'>
                        <i className="fa-solid fa-user-tie" style={{fontSize: '5em'}}></i>
                            <h1 className='mt-4'>Hello, User <i className="fa-regular fa-hand contact-hand-wave"></i></h1>
                            <p>Enter your personal details and start the journey with us.</p>
                        </div>


                    </div>
                    {/* row ends */}

            </section>
        </>
        // <div>
        //     <Headings message="CONTACT US" />

        //     <div className='login-pannel'>
        //         <div className='container-login' id="container">

        //         <div className="form-container sign-in-container">
        //                 <form onSubmit={handleSubmit} method='POST'>
        //                     <h2>Contact Us</h2>
        //                     <input required type="text" maxLength={60} value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" />
        //                     <input required type="text" maxLength={10} value={mobileNumber} onChange={handleChange} minLength={10} pattern='[0-9]{10}' placeholder="Enter Contact" />
        //                     <textarea className='contact-msg' required type="text" maxLength={250} value={formMsg} onChange={(e) => setFormMsg(e.target.value)} placeholder="Enter Message" />
        //                     <br />
        //                     <button style={{backgroundColor: '#b92ddb', border: '1px solid #b92ddb'}} type='submit'>Submit</button>
        //                 </form>
        //             </div>

        //             <div className="overlay-container">
        //                 <div className="contact-overlay">
        //                     <div className="overlay-panel overlay-right">
        //                         <h1>Hello, User!</h1>
        //                         <p>Enter your personal details and start journey with us</p>
        //                     </div>
        //                 </div>
        //             </div>

        //         </div>
        //     </div>

        // </div>
    );
}

export default Contact;