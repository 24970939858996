import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/generals/footer.css';

function Footer() {
    return (
        <>
            <footer className='footer-bg'>
                <div className='container'>

                    <div className='d-flex justify-content-center'>
                        <Link to='/'><i className="fa-brands social-icon fa-facebook"></i></Link>
                        <Link to='/'><i className="fa-brands social-icon fa-x-twitter"></i></Link>
                        <Link to='/'><i className="fa-brands social-icon fa-instagram"></i></Link>
                        <Link to='/'><i className="fa-brands social-icon fa-linkedin"></i></Link>
                    </div>


                    <div className='row mt-5'>

                        <div className='col-lg-4 col-md-6 col-12 my-3'>
                            <h5 className='mb-4'>Contact Us</h5>
                            <p><i class="fa-solid pe-2 fa-location-dot"></i>Naini, Prayagraj</p>
                            <p><i class="fa-solid pe-2 fa-phone"></i>+ 123 456 XXXX</p>
                            <p><i class="fa-solid pe-2 fa-envelope"></i>info@bazzar.com</p>
                        </div>

                        <div className='col-lg-4 col-md-6 col-12 my-3'>
                            <h5 className='mb-4'>Quick Links</h5>
                            <p><Link className='footer-link' to='/'>Home</Link></p>
                            <p><Link className='footer-link' to='/about'>About</Link></p>
                            <p><Link className='footer-link' to='/products'>Products</Link></p>
                            <p><Link className='footer-link' to='/contact'>Contact</Link></p>
                        </div>

                        <div className='col-lg-4 col-md-6 col-12 my-3'>
                        <h5 className='mb-4'>Our Services</h5>
                        <p><Link className='footer-link' to='/'>Home Appliances</Link></p>
                            <p><Link className='footer-link' to='/'>Kitchen Appliances</Link></p>
                            <p><Link className='footer-link' to='/'>Accessories</Link></p>
                            <p><Link className='footer-link' to='/'>Entertainment</Link></p>
                            <p><Link className='footer-link' to='/'>Technical Support</Link></p>

                        </div>

                    </div>
                    {/* row ends */}

                </div>
            </footer>

            <div className='bg-orange py-1 text-center px-2'>
                <p className='mt-2'>Copyright &copy; All Rights Reserved. Developed By <Link to="https://dectwinbilling.com/">Dectwin Services Pvt. Ltd.</Link></p>
            </div>

        {/* // <footer>
        //     <div className="footer">
        //         <div className="container">
        //             <div className="row">
        //                 <div className="col-md-6 offset-md-3">
        //                     <ul className="social">
        //                         <li> <Link to='/'><i className="fa-brands fa-facebook-f"></i></Link></li>
        //                         <li> <Link to='/'><i className="fa-brands fa-twitter"></i></Link></li>
        //                         <li> <Link to='/'><i className="fa-brands fa-instagram"></i></Link></li>
        //                         <li> <Link to='/'><i className="fa-brands fa-linkedin"></i></Link></li>
        //                     </ul>
        //                 </div>
        //             </div>
        //             <div className="footer-content">
        //                 <div className="contact">
        //                     <h3>Conatct us</h3>
        //                     <span>Naini, Prayagraj<br />
        //                     </span>
        //                 </div>
        //                 <div className="contact">
        //                     <h3>ADDITIONAL LINKS</h3>
        //                     <ul className="lik">
        //                         <li> <Link to='/'>Home</Link></li>
        //                         <li> <Link to='/'>About us</Link></li>
        //                         <li> <Link to='/'>Product</Link></li>
        //                         <li> <Link to='/'>Contact us</Link></li>
        //                     </ul>
        //                 </div>
        //                 <div className="contact">
        //                     <h3>service</h3>
        //                     <ul className="lik">
        //                         <li> <Link to='/'>Home Appliances</Link></li>
        //                         <li> <Link to='/'>Kitchen Appliances</Link></li>
        //                         <li> <Link to='/'>Accessories</Link></li>
        //                         <li> <Link to='/'>Entertainment</Link></li>
        //                         <li> <Link to='/'>Technical support</Link></li>
        //                     </ul>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="copyright">
        //             <p>Copyright &copy; 2024 All Right Reserved By <Link to="https://dectwinbilling.com/">Dectwin Services Pvt. Ltd.</Link></p>
        //         </div>

        //     </div>
        // </footer > */}
        </>
    );
}

export default Footer;