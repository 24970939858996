import React, { useState } from 'react'
// import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const editPopup = {
    backgroundColor: 'white',
}

const AddModal = ({ productData }) => {

    const [count, setCount] = useState(1);


    const dec = () => {
        if(count>1)
        setCount(prev=> prev-1);
    }

    const inc = () => {
        setCount(prev=> prev+1);
    }

    // const updatedPrice = productData.purchase_price * count;


    return (

        <>
            <Popup trigger=
                {<button className='click-btn'>Add to Cart&nbsp;
                    <i className="fa-solid fa-cart-shopping"></i></button>}
                modal nested style={editPopup} >

                {
                    close => (
                        <div className='py-4 px-4'>
                            <div className='d-flex justify-content-between'>
                                <h4 className='text-orange'>Products in Cart</h4>
                                <button className='click-btn' onClick={() => close()}>Close Cart</button>
                            </div>
                            {/* flex ends */}

                            <hr />

                            <div className='cart-product mt-4'>
                                <img src={productData.image} alt='Product' className='img-fluid' />

                                <div className='px-3'>
                                    <h6>{productData.name}</h6>
                                    <span>₹{productData.purchase_price * count}</span><br />

                                    <div>
                                        <button className='cart-operator minus' onClick={dec}>-</button>
                                        <span className='px-2'>{count}</span>
                                        {/* <input type="num" class="num-input" value="1" min="1" /> */}
                                        <button className='cart-operator plus' onClick={inc}>+</button>

                                        <button className='click-btn mt-1 ms-3'>Remove<i class="fa-solid fa-xmark ps-2"></i></button></div>
                                </div>
                            </div>

                        </div>
                    )
                }

            </Popup>

        </>
    )
}

export default AddModal;
