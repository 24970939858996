import React from 'react';
import { Link } from 'react-router-dom';
import EditModal from './editProduct';
import AddModal from './addProduct';
// import prod1 from '../../../utils/products/prod1.jpeg';
import prod2 from '../../../utils/products/prod2.jpeg';
// import prod3 from '../../../utils/products/prod3.jpeg';
// import prod4 from '../../../utils/products/prod4.jpeg';
// import prod5 from '../../../utils/products/prod5.jpeg';
// import prod6 from '../../../utils/products/prod6.jpeg';


const cardStyles = {
    backgroundColor: '#ffffff',
    width: '18rem',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    overflow: 'hidden',
    border: '1px solid #ddd',
    margin: '20px',
    transition: 'box-shadow 0.3s ease, transform 0.3s ease',
};

const imgContainerStyles = {
    overflow: 'hidden',
    transition: 'transform 0.3s ease',
};

const imgStyles = {
    width: '100%',
    height: '180px',
    objectFit: 'cover',
};

const cardBodyStyles = {
    padding: '15px',
};

const cardTitleStyles = {
    fontSize: '1.25rem',
    margin: '0 0 10px 0',
    fontWeight: 'bold',
};

const priceContainerStyles = {
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2rem',
};

const originalPriceStyles = {
    color: '#555',
    textDecoration: 'line-through',
    marginRight: '10px',
};

const discountedPriceStyles = {
    color: '#e74c3c',
    fontWeight: 'bold',
};

const cardTextStyles = {
    fontSize: '1rem',
    marginBottom: '15px',
    color: '#555',
};

// const buttonStyles = {
//     backgroundColor: '#007bff',
//     border: 'none',
//     color: '#fff',
//     padding: '0.6em',
//     borderRadius: '0px',
//     cursor: 'pointer',
//     fontSize: '0.9em',
// };

function MyCard({ productData }) {

    const clientIs = localStorage.getItem('client_is');    
    const isLoggedIn = clientIs === null;
    const clientDetails = JSON.parse(clientIs);

    return (

        <div
            style={cardStyles}
            className="card-hover"
        >
            <div className="img-container" style={imgContainerStyles}>
                <img
                    src={prod2}
                    alt="Card top"
                    style={imgStyles}
                />
            </div>
            <div style={cardBodyStyles}>
                <Link to={`/products/${productData.id}`}>
                    <h5 style={cardTitleStyles}>{productData.name}</h5>
                </Link>
                <p style={cardTextStyles}>
                    {productData.description}
                </p>

                {/* Price Section */}
                <div style={priceContainerStyles}>
                    <span style={originalPriceStyles}>₹{productData.sale_price}</span>
                    <span style={discountedPriceStyles}>₹ {productData.purchase_price}</span>
                </div>

                <div className='d-flex justify-content-between'>
                    <AddModal productData={productData} />
                    {/* <button style={buttonStyles}>Add to Cart <i className="fa-solid fa-cart-shopping"></i></button> */}

                    {/* <EditModal productData={productData} /> */}
                    {!isLoggedIn && clientDetails.isAdmin ? <EditModal productData={productData} /> : ""}
                    
                    {/* <button style={buttonStyles}>Edit Product <i className="fa-regular fa-pen-to-square"></i></button> */}
                </div>
            </div>
        </div>

    );
};

export default MyCard;
