import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/body_comps/login.css';
import Headings from '../generals/headings';
import axios from 'axios';

function Login(props) {
    const [isPanelActive, setIsPanelActive] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [name, setName] = useState('');
    const [clientId, setClientId] = useState('');
    const [referId, setReferId] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post('http://localhost:8081/api/signup', {
            clientId: clientId,
            referId: referId,
        }).then((response) => {
            const isValid = response.data.isValid;
            if (isValid === "TBI3236") {
                if (referId === "TBI3236") {
                    singUp();
                } else {
                    props.handleToast({ toastMsg: "Please Enter a valid refer code", toastColor: "#FF0000" });
                }
            } else if (isValid === 1) {
                singUp();
            } else if (isValid === 2) {
                props.handleToast({ toastMsg: "Failed! Client Id Already Exists!!", toastColor: "#FF0000" });
            } else {
                props.handleToast({ toastMsg: "Something went wrong! Please verify details", toastColor: "#FF0000" });
            }
        }).catch((error) => {
            props.handleToast({ toastMsg: "Failed! Something went wrong", toastColor: "#FF0000" });
        });
    };

    function singUp() {
        axios.post('http://localhost:8081/api/addClient', {
            name: name,
            contact: mobileNumber,
            clientId: clientId,
            referId: referId,
            password: password,
        }).then((response) => {
            props.handleToast({ toastMsg: "Congratulations! Sign Up successfull", toastColor: "#4CAF50" });
            handleSignIn();
        }).catch((error) => {
            props.handleToast({ toastMsg: "Failed! Something went wrong", toastColor: "#FF0000" });
        });
    }

    const signInHandler = (e) => {
        e.preventDefault();

        // Send a POST request to the backend
        axios.post('http://localhost:8081/api/login', {
            clientId: clientId,
            password: password,
        }).then((response) => {
            localStorage.setItem('client_is', JSON.stringify(response.data));
            props.handleToast({ toastMsg: "Congratulations! Login Successfull", toastColor: "#4CAF50" });
            if (response.data.isAdmin) {                
                navigate('/admin/dashboard');
            } else {
                navigate('/client/dashboard');
            }
        }).catch((error) => {
            props.handleToast({ toastMsg: "Something went wrong! Enter valid client Id and password", toastColor: "#FF0000" });
        });
    };

    // Handle change in mobile number input
    const handleChange = (e) => {
        const value = e.target.value;

        // Ensure the input is numeric and no more than 10 digits
        if (value.length <= 10 && /^[0-9]*$/.test(value)) {
            if (value.length > 0 && !(["6", "7", "8", "9"].includes(value[0]))) {
                setMobileNumber('');
                props.handleToast({ toastMsg: "Invalid Contact! Please enter a valid mobile number", toastColor: "#FF0000" });
            } else {
                setMobileNumber(value);
            }
        }
    };

    // Handler for sign up button
    const handleSignUp = () => {
        setIsPanelActive(true);
        setMobileNumber('');
        setName('');
        setClientId('');
        setReferId('');
        setPassword('');
    };

    // Handler for sign in button
    const handleSignIn = () => {
        setIsPanelActive(false);
        setMobileNumber('');
        setName('');
        setClientId('');
        setReferId('');
        setPassword('');
    };

    return (
        <div>
            <Headings message={isPanelActive ? "SIGNUP NOW" : "LOGIN NOW"} />
            <div className='login-pannel'>
                <div className={`container-login ${isPanelActive ? 'right-panel-active' : ''}`} id="container">
                    <div className="form-container sign-up-container">
                        <form onSubmit={handleSubmit} method='POST'>
                            <h2>Create Account</h2>
                            {/* <div className="social-container">
                                <Link to="/" className="social"><i className="fab fa-facebook-f"></i></Link>
                                <Link to="/" className="social"><i className="fab fa-google-plus-g"></i></Link>
                                <Link to="/" className="social"><i className="fab fa-linkedin-in"></i></Link>
                            </div>
                            <span>or use your email for registration</span> */}
                            <input required type="text" maxLength={60} value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" />
                            <input required type="text" maxLength={10} value={mobileNumber} onChange={handleChange} minLength={10} pattern='[0-9]{10}' placeholder="Enter Contact" />
                            <input required type="text" maxLength={20} value={referId} onChange={(e) => setReferId(e.target.value)} placeholder="Enter Refer ID" />
                            <input required type="text" maxLength={20} value={clientId} onChange={(e) => setClientId(e.target.value)} placeholder="Create Client ID" />
                            <input required type="password" minLength={5} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Create Password" />
                            <br />
                            <button type='submit'>Sign Up</button>
                        </form>
                    </div>
                    <div className="form-container sign-in-container">
                        <form onSubmit={signInHandler} method='POST'>
                            <h1>Sign in</h1>
                            <br />
                            <br />
                            {/* <div className="social-container">
                                <Link to="/" className="social"><i className="fab fa-facebook-f"></i></Link>
                                <Link to="/" className="social"><i className="fab fa-google-plus-g"></i></Link>
                                <Link to="/" className="social"><i className="fab fa-linkedin-in"></i></Link>
                            </div>
                            <span>or use your account</span> */}
                            <input required type="text" maxLength={20} value={clientId} onChange={(e) => setClientId(e.target.value)} placeholder="Enter Client ID" />
                            <input required type="password" minLength={5} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter Password" />
                            <br />
                            {/* <Link to="/">Forgot your password?</Link> */}
                            <button type='submit'>Sign In</button>
                        </form>
                    </div>
                    <div className="overlay-container">
                        <div className="overlay">
                            <div className="overlay-panel overlay-left">
                                <h1>Welcome Back!</h1>
                                <p>To keep connected with us please login with your personal info</p>
                                <button className="ghost" id="signIn" onClick={handleSignIn}>Sign In</button>
                            </div>
                            <div className="overlay-panel overlay-right">
                                <h1>Hello, User!</h1>
                                <p>Enter your personal details and start journey with us</p>
                                <button className="ghost" id="signUp" onClick={handleSignUp}>Sign Up</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;