import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../styles/product_details.css';
import AddModal from './addProduct';
import prod1 from '../../../utils/products/prod1.jpeg';
import prod2 from '../../../utils/products/prod2.jpeg';
import prod3 from '../../../utils/products/prod3.jpeg';
import prod4 from '../../../utils/products/prod4.jpeg';
import prod5 from '../../../utils/products/prod5.jpeg';
import prod6 from '../../../utils/products/prod6.jpeg';

function ProductDetails() {
    const { prodId } = useParams();
    const [product, setProduct] = useState({});
    const [mainImage, setMainImage] = useState(prod1);

    useEffect(() => {
        // Check if products are already in localStorage
        const storedProducts = localStorage.getItem('products');
        if (storedProducts) {
            // Use cached data if available
            const allProducts = JSON.parse(storedProducts);
            setProduct(allProducts.find(item => item.id === parseInt(prodId)));
        }
    }, [prodId]);

    // Function to handle thumbnail click
    const handleThumbnailClick = (image) => {
        setMainImage(image);
    };

    return (
        <>
            {/* Individual Product details section */}
            <section className='bg-light py-5'>
                <div className='container'>

                    <div className='row product-card'>

                        <div className='col-lg-6 col-md-6 col-12 my-2'>

                        <img className="main-image img-fluid" src={mainImage} alt="Main Product" />

                            <div className="thumbnail-container my-2">
                                {[prod1, prod2, prod3, prod4, prod5, prod6].map((image, index) => (
                                    <img
                                        key={index}
                                        src={image}
                                        alt={`Thumbnail ${index}`}
                                        className={`thumbnail-image ${mainImage === image ? 'active-thumbnail' : ''}`}
                                        onClick={() => handleThumbnailClick(image)}
                                    />
                                ))}
                            </div>

                            {/* <img src={product.image} className='img-fluid' alt='Individual Product' />     */}
                        </div>


                        <div className='col-lg-6 col-md-6 col-12 my-2'>
                            <h2>{product.name}</h2>
                            <hr />
                            <h6 className='text-muted'>Category: {product.product_type}</h6>

                            <p className='my-4'>Product description: {product.description}</p>
                            <AddModal productData={product} />

                        </div>

                    </div>
                    {/* row ends */}

                </div>
            </section>
            {/* Individual Product details section ends */}

        </>
        // <div className="product-details-container">

        //     <div className="thumbnail-container">
        //         {[product.image, "https://img.freepik.com/free-photo/smart-phone-with-sunrise-mountain-screen-3d-rendering_1142-50920.jpg?size=626&ext=jpg&ga=GA1.1.869836405.1706095704&semt=ais_hybrid"].map((image, index) => (
        //             <img
        //                 key={index}
        //                 src={image}
        //                 alt={`Thumbnail ${index}`}
        //                 className={`thumbnail-image ${mainImage === image ? 'active-thumbnail' : ''}`}
        //                 onClick={() => handleThumbnailClick(image)}
        //             />
        //         ))}
        //     </div>

        //     <img className="main-image" src={mainImage} alt="Main Product" />

        //     <div className="product-info">
        //         <h1>{product.name}</h1>
        //         <p className="product-description">{product.description}</p>
        //         <h3 className="product-description">Category: {product.product_type}</h3>

        //         <p className="product-price">
        //             <span className="discounted-price">₹ {product.sale_price}</span>
        //             {/* <span className="original-price">${product.sale_price}</span> */}
        //         </p>

        //         <button className="add-to-cart-btn">Add to Cart</button>
        //     </div>
        // </div>
    );
};

export default ProductDetails;
