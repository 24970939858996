import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

function BarChart() {
  const chartRef = useRef(null);

  const data = {
    labels: ['Electronics', 'Furniture', 'Clothing', 'Toys', 'Books', 'Stationary'],
    datasets: [
      {
        label: 'Sales',
        data: [120, 90, 150, 80, 60, 110],
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          // Create a gradient for the normal bar fill
          const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradient.addColorStop(0, '#36D1DC'); // Lighter blue
          gradient.addColorStop(1, '#5B86E5'); // Deep blue

          return gradient;
        },
        hoverBackgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          // Darker gradient on hover
          const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradient.addColorStop(0, '#1E90FF'); // Darker shade of blue
          gradient.addColorStop(1, '#007ACC'); // Dark navy

          return gradient;
        },
        borderColor: 'rgba(0, 123, 255, 1)',
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            family: 'Poppins',
            weight: '600',
          },
          color: '#333',
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            family: 'Poppins',
            weight: '600',
          },
          color: '#333',
        },
      },
    },
    animation: {
      duration: 2000,
      easing: 'easeInOutBounce',
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: 16,
            family: 'Poppins',
            weight: 'bold',
          },
          color: '#333',
        },
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Bar ref={chartRef} data={data} options={options} />
    </div>
  );
}

export default BarChart;
