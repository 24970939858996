import React from 'react';
import '../../styles/body_comps/about.css';

function Headings(props) {
    return (
        <div className="bg-orange py-2">
            <div className="container text-center py-5">
                <h2 className='fw-bold'>{props.message}</h2>
            </div>
        </div>
    );
}

export default Headings;