import React from 'react';
import '../../styles/body_comps/home.css';
import { Link } from 'react-router-dom';
import banner1 from "../../../utils/homepage/banner.jpg";
import banner2 from "../../../utils/homepage/banner.jpg";
import service1 from "../../../utils/homepage/service1.png";
import service2 from "../../../utils/homepage/service2.png";
import service3 from "../../../utils/homepage/service3.png";
import service4 from "../../../utils/homepage/service4.png";
import service5 from "../../../utils/homepage/service5.png";
import service6 from "../../../utils/homepage/service6.png";
import WhyUs from '../generals/why_us';

function Home() {
    // const slides = [banner1, banner2, banner3];
    // const [currentIndex, setCurrentIndex] = useState(0);
    // const [fade, setFade] = useState(true);

    // const nextSlide = useCallback(() => {
    //     setFade(false);
    //     setTimeout(() => {
    //         setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
    //         setFade(true);
    //     }, 600);
    // }, [slides.length]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         nextSlide();
    //     }, 2500);
    //     return () => clearInterval(interval);
    // }, [currentIndex, nextSlide]);

    // const prevSlide = () => {
    //     setFade(false);
    //     setTimeout(() => {
    //         setCurrentIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
    //         setFade(true);
    //     }, 600);
    // };

    return (
        <>

            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" data-bs-interval="2000">
                <div className="carousel-inner">
                    <div className="carousel-item carousel-item-image active">
                        <img src={banner1} className="d-block w-100" alt="Slider1" />
                    </div>
                    <div className="carousel-item carousel-item-image">
                        <img src={banner2} className="d-block w-100" alt="Slider2" />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <i className="fa-solid fa-caret-left"></i>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <i className="fa-solid fa-caret-right"></i>
                </button>

                <div className='carousel-content my-2'>
                    <hr className='carousel-hr' />
                    <h2 className='carousel-heading my-4'>Explore Quality Products</h2>
                    <Link to='/products'><button className='click-btn'>Check Latest Range</button></Link>
                </div>

            </div>
            {/* Carousel Ends */}

            <WhyUs />


            <section className='bg-light py-5'>
                <div className='container text-center'>

                    <div className='my-4'>
                        <h2 className='heading2'>Easy <strong className="text-orange">Process</strong></h2>
                        <span>Quick and effective way to get your devices at your doorstep.</span>
                    </div>

                    <div className='row my-4'>

                        <div className='col-lg-4 col-md-6 col-12 my-3'>
                            <div className='choose-area'>
                                <img src={service1} alt='why choose us' />
                                <div className='my-4'>
                                    <h5>Fast Service</h5>
                                    <p>Get your electronics repaired or serviced in no time with our express solutions. We ensure quick turnarounds without compromising on quality.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-12 my-3'>
                            <div className='choose-area'>
                                <img src={service2} alt='why choose us' />
                                <div className='my-4'>
                                    <h5>Secure Payments</h5>
                                    <p>Shop with confidence using our secure payment gateways, ensuring a hassle-free and safe transaction experience every time.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-12 my-3'>
                            <div className='choose-area'>
                                <img src={service3} alt='why choose us' />
                                <div className='my-4'>
                                    <h5>Expert Team</h5>
                                    <p>Our team of certified experts is here to assist you with everything from product recommendations to technical support, ensuring top-notch service.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-12 my-3'>
                            <div className='choose-area'>
                                <img src={service4} alt='why choose us' />
                                <div className='my-4'>
                                    <h5>Affordable Services</h5>
                                    <p>We offer competitive pricing on all electronic repair and maintenance services, making sure you get the best value for your money.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-12 my-3'>
                            <div className='choose-area'>
                                <img src={service5} alt='why choose us' />
                                <div className='my-4'>
                                    <h5>90 Days Warranty</h5>
                                    <p>Enjoy peace of mind with our 90-day warranty on all repairs and purchases. If anything goes wrong, we've got you covered.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-12 my-3'>
                            <div className='choose-area'>
                                <img src={service6} alt='why choose us' />
                                <div className='my-4'>
                                    <h5>Award Winning</h5>
                                    <p>Proud to be recognized as a top electronics shop. Our commitment to customer satisfaction has earned us multiple awards.</p>
                                </div>
                            </div>
                        </div>


                    </div>
                    {/* row ends */}


                </div>
            </section>


            {/* // <section >
        //     <div id="main_slider" className="carousel slide banner-main" style={{position: 'relative'}}>
        //         <div className={`carousel-item ${fade ? 'fade-in' : 'fade-out'}`}>
        //             <img className="slide-image" width={"100%"} src={slides[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
        //             <div className="container">
        //                 <div className="carousel-caption">
        //                     <h1>
        //                         Our <br />
        //                         <strong className="black_bold">Latest</strong><br />
        //                         <strong className="yellow_bold">Product</strong>
        //                     </h1>
        //                     <br />
        //                     <Link to="/product">See more Products</Link>
        //                 </div>
        //             </div>
        //         </div>

        //         <button className="carousel-control-prev" onClick={nextSlide}>
        //             <i className="fa fa-angle-right"></i>
        //         </button>
        //         <button className="carousel-control-next" onClick={prevSlide}>
        //             <i className="fa fa-angle-left"></i>
        //         </button>
        //     </div>

        //     <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

        //     <WhyUs />

        //     <div className="service">
        //         <div className="container">
        //             <div className="row">
        //                 <div className="col-md-8 offset-md-2">
        //                     <div className="title">
        //                         <h2>Service <strong className="black">Process</strong></h2>
        //                         <span>Easy and effective way to get your latest device</span>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="row">
        //                 <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        //                     <div className="service-box">
        //                         <i><img src={service1} alt='service-images' /></i>
        //                         <h3>Fast service</h3>
        //                         <p>Get your electronics repaired or serviced in no time with our express solutions. We ensure quick turnarounds without compromising on quality.</p>
        //                     </div>
        //                 </div>
        //                 <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        //                     <div className="service-box">
        //                         <i><img src={service2} alt='service-images' /></i>
        //                         <h3>Secure payments</h3>
        //                         <p>Shop with confidence using our secure payment gateways, ensuring a hassle-free and safe transaction experience every time.</p>
        //                     </div>
        //                 </div>
        //                 <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        //                     <div className="service-box">
        //                         <i><img src={service3} alt='service-images' /></i>
        //                         <h3>Expert team</h3>
        //                         <p>Our team of certified experts is here to assist you with everything from product recommendations to technical support, ensuring top-notch service.</p>
        //                     </div>
        //                 </div>
        //                 <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        //                     <div className="service-box">
        //                         <i><img src={service4} alt='service-images' /></i>
        //                         <h3>Affordable services</h3>
        //                         <p>We offer competitive pricing on all electronic repair and maintenance services, making sure you get the best value for your money.</p>
        //                     </div>
        //                 </div>
        //                 <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        //                     <div className="service-box">
        //                         <i><img src={service5} alt='service-images' /></i>
        //                         <h3>90 Days warranty</h3>
        //                         <p>Enjoy peace of mind with our 90-day warranty on all repairs and purchases. If anything goes wrong, we've got you covered.</p>
        //                     </div>
        //                 </div>
        //                 <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        //                     <div className="service-box">
        //                         <i><img src={service6} alt='service-images' /></i>
        //                         <h3>Award winning</h3>
        //                         <p>Proud to be recognized as a top electronics shop. Our commitment to customer satisfaction has earned us multiple awards.</p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section> */}
        </>
    );
}

export default Home;