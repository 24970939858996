import React from 'react';

const styles = {
    width: '80vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    bottom: '20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '15px',
    borderRadius: '4px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    transition: 'opacity 0.5s ease-in-out',
    fontSize: '16px',
};


const closeButtonStyle = {
    position: 'absolute',
    right: '16px',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    fontSize: '26px',
    color: 'white',
};

function MyToast(props) {
    return props.show && (
        <div style={{ display: 'flex', width: '100vw', justifyContent: 'center' }}>
            <div style={{ ...styles, backgroundColor: props.color }}>
                <span>{props.message}</span>
                <button style={closeButtonStyle} onClick={props.onClose}>
                    &times;
                </button>
            </div>
        </div>
    );
}

export default MyToast;
