import React from 'react';
import Header from '../generals/header';
import banner1 from '../../../utils/homepage/client-banner.jpg';
import banner2 from '../../../utils/homepage/banner.jpg';
import { Link } from 'react-router-dom';
import OrderDetail from '../models/orderDetail';

function ClientDashboard({ clientDetails }) {
  return (
    <>
      <Header showNav={false} />

      {/* Section 1 */}
      <section className="bg-light py-4">
        <div className='container'>

          <div className='row'>

            <div className='col-lg-9 col-12 my-3'>
              <h3 className='fst-italic text-muted'>Hello {clientDetails.name}!</h3>

              <div id="carouselExampleIndicators" className="carousel slide carousel-fade my-4" data-bs-ride="carousel">
                <div className="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                </div>

                <div className="carousel-inner">
                  <div className="carousel-item client-carousel-item active" data-bs-interval="2000">
                    <img src={banner1} className="rounded-4 img-fluid" alt="Client Banner" />
                  </div>
                  <div className="carousel-item client-carousel-item" data-bs-interval="2000">
                    <img src={banner2} className="rounded-4 img-fluid" alt="Client Banner" />
                  </div>
                </div>

              </div>
              {/* Carousel ends */}
            </div>


<div className='col-lg-3 col-12 my-3'>
  <div className='client-cart'>
  <h4 className='text-orange'>Added in Cart</h4>
  <hr />
  

  </div>
</div>

          </div>
          {/* row ends */}


        </div>
      </section>
      {/* Section 1 Ends */}


      {/* Section 2 */}
      <section className='my-4'>
        <div className='container'>

          <div className='d-flex justify-content-between'>
            <h4>My Recent Orders</h4>
            <Link to='/client/sales'><button className='click-btn'>View All</button></Link>
          </div>

          <div className='table-responsive mt-4'>
            <table className='w-100'>
              <tr className="client-header">
              <th>Date</th>
              <th>Invoice No</th>
                <th>Customer Name</th>
                <th>Payment Mode</th>
                <th>Total Price</th>
                <th>Details</th>
              </tr>

              <tr className='client-data'>
                <td>19/10/2024</td>
                <td>Invoice_Id1</td>
                <td>Ambani</td>
                <td>Cash</td>
                <td>Rs 200000</td>
                <td><OrderDetail /></td>
                {/*  productData={productData} Pass this prop in order details */}
              </tr>
            </table>
          </div>

        </div>
      </section >
      {/* Section 2 Ends */}

    </>
  );
}

export default ClientDashboard;